import React from 'react'
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

// Elements
import ParseContent from 'components/shared/ParseContent'

// Images
import Plus from 'img/plus.inline.svg'

// Interface
import { PartnersProps } from 'components/flex/Partners/PartnersShell'
import NavLink from 'components/shared/NavLink'
import { graphql, useStaticQuery } from 'gatsby'
import BlogBink, { BlogBinkPosts, useBlogBink } from '@ubo/blog-bink'

const PartnerGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 80px;
`

const Content = styled(ParseContent)`
  @media (min-width: 576px) {
    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
      margin-bottom: 2rem;
    }

    & p {
      line-height: ${({ theme }) => theme.font.size.larger};
    }
  }
`

const ImageWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.light};
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.45);
  aspect-ratio: 1/1;

  @media (min-width: 576px) {
    border-radius: 35px;
  }

  @media (max-width: 575.98px) {
    border-radius: 12px;
  }
`

const Image = styled(Plaatjie)`
  opacity: 0.8;

  & img {
    object-fit: contain !important;
  }

  &:hover {
    opacity: 1;
  }
`

const Info = styled.div`
  & h2 {
    font-size: ${({ theme }) => theme.font.size.giga};
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }

  & span {
    color: ${({ theme }) => theme.color.primary};
    font-weight: ${({ theme }) => theme.font.weight.light};
  }

  @media (min-width: 992px) {
    & span {
      font-size: ${({ theme }) => theme.font.size.larger};
      line-height: ${({ theme }) => theme.font.size.larger};
    }
  }

  @media (min-width: 576px) {
    & h2 {
      font-size: ${({ theme }) => theme.font.size.giga};
    }
  }

  @media (max-width: 575.98px) {
    & h2 {
      font-size: ${({ theme }) => theme.font.size.large};
      line-height: ${({ theme }) => theme.font.size.large};
    }
  }
`

const Overview: React.FC<PartnersProps> = ({ fields }) => {
  const {
    allWpPartner,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.overviewPartnersQueryQuery>(graphql`
    query overviewPartnersQuery {
      allWpPartner(sort: { order: DESC, fields: date }) {
        edges {
          node {
            ...generalPartnerFragment
          }
        }
      }
    }
  `)

  const partners: unknown = allWpPartner.edges
  const postIds = fields.partners?.map((partner) => partner?.databaseId)

  return (
    <section className="my-5 pb-lg-5">
      <BlogBink
        showIds={postIds as Array<number>}
        posts={partners as BlogBinkPosts}
        id="highlighted-partners"
        limit={Number(fields.limit)}
      >
        <div className="container py-5">
          <div className="row justify-content-center">
            <div className="col-lg-11">
              <Content content={fields.description} className="mb-5 pb-lg-5" />
              <div className="row">
                <BlogGrid fields={fields} />
              </div>
            </div>
          </div>
        </div>
      </BlogBink>
    </section>
  )
}

const BlogGrid: React.FC<PartnersProps> = ({ fields }) => {
  const blogBink = useBlogBink()

  return (
    <div className="container">
      <div className="row justify-content-between">
        {blogBink.posts.map((post) => {
          const { node }: any = post

          return (
            <>
              <div className="col-4 mb-5">
                <ImageWrapper className="d-flex justify-content-center align-items-center p-2 p-sm-4 p-md-5">
                  <div className="p-lg-4">
                    <Image image={node?.recap.thumbnail} alt="" />
                  </div>
                </ImageWrapper>
              </div>
              <Info className="col-8 col-sm-7 mb-5 d-flex align-items-center">
                <div className="p-lg-5">
                  <div className="d-flex flex-column flex-sm-row align-items-sm-center mb-4">
                    <h2 className="me-4">{node.title}</h2>
                  </div>
                  <span>{node.recap.partnertype}</span>
                  {node.recap.excerpt && (
                    <Content content={node.recap.excerpt} />
                  )}
                </div>
              </Info>
            </>
          )
        })}
      </div>
    </div>
  )
}

export default Overview
